<template>
  <div class="container">
    <div>
      <span class="sp1">404</span>
      <span class="sp2">错误</span>
    </div>
    <div class="sp3">很抱歉，没有发现你要找的页面...</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped="scoped">
body,
html {
  padding: 0px;
  margin: 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#app {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  text-align: center;
}

.sp1 {
  font-size: 80px;
  font-family: Segoe UI;
  font-weight: bold;
  color: rgba(112, 112, 112, 1);
  opacity: 1;
}

.sp2 {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 59px;
  color: rgba(112, 112, 112, 1);
  opacity: 1;
}

.sp3 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 37px;
  color: rgba(112, 112, 112, 1);
  opacity: 1;
}
</style>
