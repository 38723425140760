import ElementPlus, { ElMessage, ElMessageBox } from 'element-plus';

// import 'element-plus/lib/theme-chalk/index.css';
import 'element-plus/dist/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn'; // 中文
import { nanoid } from 'nanoid';
import Easyj from 'Easyj';
// import EasyjPCUI from 'EasyjPCUI';

// 引入全局样式
import 'easyjPcTheme';

// 引入字体图标样式
import 'iconfont';

// 定义自定义全局对象
window.easyj = Easyj;

// 定义消息组件
window.easyj.$message = ElMessage;
window.easyj.$message.confirm = (msg, callback, option = '提示') => {
  let title = '提示';
  let confirmButtonText = '确定';
  let cancelButtonText = '取消';
  if (typeof (option) === 'object') {
    title = option.title || '提示';
    confirmButtonText = option.confirmButtonText || '确定';
    cancelButtonText = option.cancelButtonText || '取消';
  } else if (typeof (option) === 'string') {
    title = option;
  }
  ElMessageBox.confirm(msg, title, {
    confirmButtonText,
    cancelButtonText,
    type: 'warning',
    dangerouslyUseHTMLString: true,
  }).then((res) => {
    callback(res);
  });
};

// 简化获取URL参数的方式，并绑定到window对象上
window.easyj.$p = Easyj.html.browser.getParameter;
window.easyj.$ps = Easyj.html.browser.getParameters;
window.easyj.$uuid = nanoid;

// 定义开发、测试和生产环境对应配置文件的地址
const devConfigUrl = `./appConfig.dev.json?r=${Math.random()}`;
const sitConfigUrl = `./deploy/appConfig.sit.json?r=${Math.random()}`;
const prodConfigUrl = `./deploy/appConfig.prod.json?r=${Math.random()}`;

let configUrl = devConfigUrl;
// process.env.BASE_URL="";
// 根据运行环境获取对应的配置文件
if (process.env.NODE_ENV === 'production') { // 生产环境
  configUrl = prodConfigUrl;
  // process.env.BASE_URL="/test/prod/";
} else if (process.env.NODE_ENV === 'test') { // 测试环境
  configUrl = sitConfigUrl;
  // process.env.BASE_URL="";
}

// 通过同步AJAX方式加载配置文件
window.easyj.appConfig = Easyj.request.getsync(configUrl);
window.easyj.passType = window.easyj.$p('passType');

export function check(moduleMustLogin, callback) {
  let needLogin = false;
  if (typeof moduleMustLogin === 'undefined' || moduleMustLogin === null) {
    needLogin = window.easyj.appConfig.moduleMustLogin || false;
  } else {
    needLogin = moduleMustLogin;
  }
  window.easyj.appConfig.moduleMustLogin = needLogin;
  // 注入当前页面参数信息
  window.easyj.http.prepare(window.easyj.appConfig.servers, window.easyj.appConfig.app.transmit_params, window.easyj.$ps());
  if (needLogin) { // 需要登录
    if (!window.easyj.passType) return window.easyj.$message.error('passType不能为空');
    if (window.easyj.isAuthorized) {
      // 注入ajax请求的URL参数和头信息
      // eslint-disable-next-line
      window.easyj.http.initOk(window.easyj.appConfig.__ajaxParams, window.easyj.appConfig.__ajaxHeader);
      if (callback)callback();
    } else {
      // 登录认证处理
      const { passType } = window.easyj;
      const baseUrl = window.easyj.appConfig.servers.accountCenter;
      const loginUrl = (function loadLoginUrl() {
        return window.easyj.appConfig.app.loginPage;
      }());
      // 获取当前最外层框架页面的回跳地址
      let parent = window;
      while (parent.parent !== parent) {
        parent = parent.parent;
      }
      const redirectUrl = parent.location.href;
      Easyj.auth.check.easyj(baseUrl, passType, window.easyj.$ps(), loginUrl, redirectUrl, (res) => {
        if (res.code === 0) {
          window.easyj.isAuthorized = true;
          // 登录成功，将token信息写入appConfig对象
          // eslint-disable-next-line
          window.easyj.appConfig.__ajaxParams = {
            __yz_token: res.accessToken,
          };
          // 初始化完成
          // eslint-disable-next-line
          window.easyj.http.initOk(window.easyj.appConfig.__ajaxParams, window.easyj.appConfig.__ajaxHeader);
          if (callback)callback();
        } else {
          // 发生其它错误
          console.error(res);
          window.$message.error(res.msg);
        }
      });
    }
  } else if (callback)callback();
  return null;
}
export function appReady(moduleMustLogin, callback) {
  check(moduleMustLogin, callback);
}
export function appInit(app, callback) {
  app.use(ElementPlus, { locale });
  // app.use(EasyjPCUI);
  callback();
}
// 路由时调用验证登录状态
export function authCheck(to, from, next) {
  check(() => {
    next();
  });
}
