<template>
  <div class="nav_all">
    
    <!-- <div class="logo" @click="jumpto('./')">绘本通</div> -->
      <img src="../assets/img/facicon.png" class="flogo" @click="jumpto('./')">
      <img src="../assets/img/logo1.png" class="logo" @click="jumpto('./')">
      <div class="nav_wrap">
        <div :class="index==0?'nav_item':'nav_item_active'" @click="jumpto('./agile')">
          硬件产品
        </div>
        <div class="nav_item" @mouseover="isshow=true" @mouseleave="isshow=false">
          <div>百城Mall小程序</div>
          <div class="code_warp" v-if="isshow">
            <img src="../assets/img/code_1.jpg" class="code">
          </div>
        </div>
        <div class="nav_item" @mouseover="isshow1=true" @mouseleave="isshow1=false">
          <div>百城绘本馆</div>
          <div class="code_warp" v-if="isshow1">
            <img src="../assets/img/code_2.jpg" class="code">
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { defineComponent,ref,watch } from 'vue';
import { useRouter } from "vue-router";
export default defineComponent({
  props: ["index"],
  setup(props) {
    const router = useRouter()
    let index = props.index?props.index:0;
    const jumpto = (path)=>{
     window.vm.$router.push({
        path: path
      });
    }
    let isshow = ref(false)
    let isshow1 = ref(false)
    return {
      jumpto,
      index,
      isshow,
      isshow1
    };
  },
});
</script>

<style lang="less" scoped>
.nav_all{
  height: 100px;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
  .logo{
    height: 60px;
    width: 230px;
    margin-top: 20px;
    color: #fdbc18;
    float:left;
    cursor: pointer;
  }
  .flogo{
    width: 40px;
    height: 40px;
    float:left;
    margin-top: 30px;
    margin-left: 180px;
    margin-right: 20px;
    cursor: pointer;
  }
  .pic{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav_wrap{
    float: right;
    display: flex;
    margin-right: 100px;
    .nav_item{
      font-size: 18px;
      margin-right: 40px;
      line-height: 100px;
      color: #333;
      cursor: pointer;
    }
    .nav_item:hover{
      font-size: 18px;
      margin-right: 40px;
      line-height: 100px;
      color: #fdbc18;
      cursor: pointer;
    }
    .nav_item_active{
      font-size: 18px;
      margin-right: 40px;
      line-height: 100px;
      font-weight: 550;
      color: #fdbc18;
      cursor: pointer;
    }
    .code_warp{
      position: absolute;
      background-color: #fff;
      border-radius: 0 0 10px 10px;
      width: 180px;
      height: 180px;
      margin-left: -25px;
      box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
    }
    .code{
      width: 140px;
      height: 140px;
      margin: 20px;
    }
  }
}
</style>
