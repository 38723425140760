import { createRouter, createWebHashHistory } from 'vue-router';
import { authCheck } from '../module_initer';

const routes = [
  {
    path: '/',
    component: () => import('./pages/main.vue'),
    meta: {
      mustLogin: false,
      title: '首页',
    },
  },
  {
    path: '/agile',
    component: () => import('./pages/agile.vue'),
    meta: {
      mustLogin: false,
      title: '硬件产品',
    },
  },
  {
    path: '/404',
    component: () => import('../_404.vue'),
    meta: {
      title: '页面找不到',
    },
  },
];
let path = window.location.pathname;
if (path.split('/').length > 1) {
  path = path.substring(0, path.lastIndexOf('/'));
}

const router = createRouter({
  history: createWebHashHistory(path), // process.env.BASE_URL
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.pageYOffset = 0;
    next({ path: '/404' });
  } else if (to.meta && to.meta.mustLogin) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.pageYOffset = 0;
    authCheck(to, from, next);
  } else {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.pageYOffset = 0;
    next();
  }
});
router.afterEach((to) => {
  const { title } = to.meta;
  if (title) {
    document.title = title;
  }
});

export default router;
