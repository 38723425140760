<template>
  <div class="box">
    <el-affix :offset="0">
      <navbar :index="1" class="nav"></navbar>
    </el-affix>
    <section class="sec3">
      <div class="text_wrap">
        <div class="text_item">
          <div class="title">产品名称</div>
          <div class="desc">
            <img src="../../../assets/img/logo.png" class="img_4">
          </div>  
        </div>
        <div class="text_item">
          <div class="title">外观</div>
          <div class="desc">
            <div style="text-align:center;">
              <img src="../../../assets/img/agile_1.png" class="img_0">
              <div>可配天空蓝、可爱粉</div>
            </div>
          </div>
        </div>
        <div class="text_item">
          <div class="title">尺寸和重量</div>
          <div class="desc">
            <div>长度：175mm</div>
            <div>宽度：121mm</div>
            <div>厚度：10mm</div>
            <div>重量：168g（可能有误差）</div>
          </div>
        </div>
        <div class="text_item">
          <div class="title">存储容量</div>
          <div class="desc">
            <div>32GB eMMC</div>
          </div>  
        </div>
        <div class="text_item">
          <div class="title">屏幕显示</div>
          <div class="desc">
            <div style="font-weight: 700;">彩色墨水屏（元太标准）</div>
            <img src="../../../assets/img/agile_2.png" class="img_1">
            <div>6寸彩色墨水屏（对角线）</div>
            <div>分辨率：黑白300ppi ；彩色120ppi</div>
            <div>前置光源：E-ink 自带</div>
          </div>  
        </div>
        <div class="text_item">
          <div class="title">操作系统</div>
          <div class="desc">
            <div>epdUI 1.0（基于OBOOK OS）</div>
          </div>  
        </div>
        <div class="text_item">
          <div class="title">触控操作</div>
          <div class="desc">
            <div>电容触控</div>
          </div>  
        </div>
        <div class="text_item">
          <div class="title">芯片</div>
          <div class="desc">
            <div>主芯片：B300（四核 1.8GHz）</div>
            <div>运行内存：1GB LPDDR3</div>
          </div>  
        </div>
        <div class="text_item">
          <div class="title">无线网络</div>
          <div class="desc">
            <div>802.11.b/g/n 无线局域网</div>
            <div>蓝牙4.2无线技术</div> 
          </div>  
        </div>
        <div class="text_item">
          <div class="title">外部按键和接口</div>
          <div class="desc">
            <img src="../../../assets/img/agile_3.png" class="img_2">
          </div>  
        </div>
        <div class="text_item">
          <div class="title">电源和电池</div>
          <div class="desc">
            <div>容量：1800mAh（ 内置锂聚合物电池）</div>
            <div>充电：5V/1A</div>
            <div>续航：连续翻页次数1万次以上（阅读时间大于30小时）</div>
          </div>  
        </div>
        <div class="text_item">
          <div class="title">操作环境</div>
          <div class="desc">
            <div>工作环境温度： 0°C 至 40°C</div>
          </div>  
        </div>
        <div class="text_item" style="border-bottom:0;">
          <div class="title">产品认证</div>
          <div class="desc">
            <div class="flex" @click="gotoPdf"><img src="../../../assets/img/3c_icon.png" class="icon"><strong class="text">国家产品3C认证证书</strong><span>(点击查看证书)</span></div>
            <img src="../../../assets/img/3c_code.png" class="code">
          </div>  
        </div>
      </div>
    </section>
    <section class="footer">
      <!-- <div class="logo">绘本通</div> -->
      <img src="../../../assets/img/logo1.png" class="logo"/>
      <div class="code_wrap">
        <div class="code_item">
        <img src="../../../assets/img/code_1.jpg" class="code">
        </div>
        <div class="code_item">
        <img src="../../../assets/img/code_2.jpg" class="code">
        </div>
      </div>
    </section>
    <div class="copyright">CopyRight © 2021 杭州百城智联科技有限公司 &nbsp;&nbsp;&nbsp;&nbsp; 浙ICP备17033259号-2</div>
  </div>
</template>

<script>
import { defineComponent,ref,onMounted, watch } from 'vue';
import navbar from '@/components/navbar.vue';
import pdf from '@/assets/img/3c_content.png';
import { useRouter } from "vue-router";
export default defineComponent({
  components:{navbar},
  setup() {
    const gotoPdf = ()=>{
      window.open(pdf);
    }
    return {
      gotoPdf
    };
  },
});
</script>

<style lang="less" scoped>
@import "~@/assets/less/main.less";
</style>
