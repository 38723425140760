<template>
  <div class="box">
    <el-affix :offset="0" style="width:100%;">
      <navbar class="nav"></navbar>
    </el-affix>
    <div class="nav_item">
      <img src="../../../assets/img/banner.png" class="pic">
    </div>
    <section class="sec1 card-wrap">
      <div class="card_item">
        <img src="../../../assets/img/card_child.png" class="icon">
        <div class="title">儿童专属</div>
        <div class="desc">专为儿童绘本阅读设计</div>
      </div>
      <div class="card_item">
        <img src="../../../assets/img/card_book.png" class="icon">
        <div class="title">纯粹绘本</div>
        <div class="desc">只有绘本、故事和音乐，没有其他干扰</div>
      </div>
      <div class="card_item">
        <img src="../../../assets/img/card_eye.png" class="icon">
        <div class="title">类纸护眼</div>
        <div class="desc">和“纸”一样的成像原理，保护眼睛发育</div>
      </div>
    </section>
    <section class="sec2">
      <div class="head">
        <div class="title">丰富“绘本” 集于一身</div>
        <div class="desc">聚集中国少儿出版社、培生教育、PNSO等优秀儿童出版社，拥有众多受儿童喜爱的绘本IP，如植物大战僵尸、小霸王龙等。小小的机身之内，藏着一个绘本馆。</div>
      </div>
      <div class="list_wrap">
        <div class="list_item">
          <img src="../../../assets/img/book_1.png" class="pic">
          <div class="desc">PNSO出版的我有一只霸王龙系列</div>
        </div>
        <div class="list_item">
          <img src="../../../assets/img/book_2.png" class="pic">
          <div class="desc">中少出版社的植物大战僵尸系列</div>
        </div>
        <!-- <div class="list_item">
          <img src="../../../assets/img/book_3.png" class="pic">  
          <div class="desc">中少出版社的猫武士系列</div>
        </div>
        <div class="list_item">
          <img src="../../../assets/img/book_4.png" class="pic">
          <div class="desc">中少出版社的熊武士系列</div>
        </div> -->
      </div>
    </section>
    <section class="sec1">
      <div class="img_wrap">
        <div class="img_item">
          <img src="../../../assets/img/img_1.png" class="pic">
          <div class="item_bottom">
            <div class="title">化身同伴，给孩子讲故事</div>
            <div class="desc">有声绘本，会在孩子点击后，用亲和的声音给孩子讲述绘本中的故事，陪伴孩子畅游故事的海洋。</div>
          </div>
        </div>
        <div class="img_item">
          <img src="../../../assets/img/img_2.jpg" class="pic">
          <div class="item_bottom">
            <div class="title">这个屏幕，和“纸”一样护眼</div>
            <div class="desc">采用国际标准“E-ink”电子墨水屏，和普通“纸”的成像原理一样，保护孩子发育中的眼睛。</div>
          </div>
        </div>
        <div class="img_item">
          <img src="../../../assets/img/img_7.png" class="pic">
          <div class="item_bottom">
            <div class="title">小小身躯，可爱的外形</div>
            <div class="desc">6寸的机身，配上柔软的彩色卡通个性套，适合于儿童手握，成人甚至可以装进口袋。</div>
          </div>
        </div>
        <div class="img_item">
          <img src="../../../assets/img/img_4.png" class="pic">
          <div class="item_bottom">
            <div class="title">epdUI 1.0，专为儿童设计</div>
            <div class="desc">基于儿童心智、墨水屏特点设计的视觉和交互系统，贴合儿童看绘本场景，去除多余操作，帮助儿童沉浸于绘本世界。</div>
          </div>
        </div>
        <div class="img_item">
          <img src="../../../assets/img/img_5.png" class="pic">
          <div class="item_bottom">
            <div class="title">不肯睡觉的孩子，有它陪伴</div>
            <div class="desc">我们贴心设计了“一键哄睡”，可以给孩子听帮助入眠的音乐； 或听睡前故事，慢慢入睡。</div>
          </div>
        </div>
        <div class="img_item">
          <img src="../../../assets/img/img_6.png" class="pic">
          <div class="item_bottom">
            <div class="title">出游路上，随身携带</div>
            <div class="desc">家庭出游路上，都可以随身携带，在阳光下看绘本通，宛如阅读实体纸质绘本般舒适。</div>
          </div>
        </div>
      </div>
    </section>
    <section class="footer">
      <!-- <div class="logo">绘本通</div> -->
      <img src="../../../assets/img/logo1.png" class="logo"/>
      <div class="code_wrap">
        <div class="code_item">
        <img src="../../../assets/img/code_1.jpg" class="code">
        </div>
        <div class="code_item">
        <img src="../../../assets/img/code_2.jpg" class="code">
        </div>
      </div>
    </section>
    <div class="copyright">CopyRight © 2021 杭州百城智联科技有限公司 &nbsp;&nbsp;&nbsp;&nbsp; 浙ICP备17033259号-2</div>
  </div>
</template>

<script>
import { defineComponent,ref,onMounted } from 'vue';
import navbar from '@/components/navbar.vue';
import { useRouter } from "vue-router";
export default defineComponent({
  components:{navbar},
  setup() {
    //动画
    return {
    };
  },
});
</script>

<style lang="less" scoped>
@import "~@/assets/less/main.less";
</style>
