import { createApp } from 'vue';
import { appReady, appInit } from '../module_initer';
import module from './index.vue';
import router from './router_index';
import 'lib-flexible';

function init() {
  const app = createApp(module);
  appInit(app, () => {
    app.use(router);
    window.vm = app.mount('#app');
  });
}

appReady(false, init);
